<template>
  <v-container fluid class="pa-0">
    <banner :title="$t('programs.programsRonDonMiguel')"></banner>
    <v-container fluid class="pa-lg-16 pa-10">
      <v-row class="ma-0 mx-lg-14 justify-center justify-lg-start">
        <h2 class="copperplate h1 text-center text-lg-left">
          <span class="josefin-medium h1"> Ron Don Miguel</span>
          <br />
          Private stock
        </h2>
      </v-row>
      <v-row class="mx-0 mx-lg-14">
        <p
          class="josefin-light p text-center text-lg-left"
          v-html="$t('programs.privateStockDescription')"
        ></p>
      </v-row>
    </v-container>
    <v-row>
      <v-img src="/barriles-about.jpg" width="100%" height="400"></v-img>
    </v-row>
    <v-row class="odd ">
      <v-container class="pa-lg-16 pa-10">
        <h2 class="copperplate h2 text-center text-lg-left">{{ $t("programs.howDoesItWork") }}</h2>
        <p
          class="josefin-light p mt-10 text-center text-lg-left"
          v-html="$t('programs.howDoesItWorkDescription')"
        ></p>
      </v-container>
    </v-row>
    <v-row>
      <v-container class="pa-lg-16 pa-10">
        <h2 class="copperplate h2 text-center text-lg-left">
          {{ $t("programs.whatYouWillReceive") }}
        </h2>
        <v-row>
          <v-col cols="12" lg="5" class="d-flex align-center">
            <p
              class="josefin-light p text-center text-lg-left"
              v-html="$t('programs.whatYouWillReceive1')"
            ></p>
          </v-col>
          <v-col cols="12" lg="7">
            <v-img src="/slider/slider4.jpeg"></v-img>
          </v-col>
        </v-row>
        <v-row class="flex-lg-row-reverse">
          <v-col cols="12" lg="5" class="d-flex align-center">
            <p
              class="josefin-light p text-center text-lg-left"
              v-html="$t('programs.whatYouWillReceive2')"
            ></p>
          </v-col>
          <v-col cols="12" lg="7">
            <v-img src="@/assets/palos-front.png"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="5" class="d-flex align-center">
            <p
              class="josefin-light p text-center text-lg-left"
              v-html="$t('programs.whatYouWillReceive3')"
            ></p>
          </v-col>
          <v-col cols="12" lg="7">
            <v-img src="@/assets/Barriles.jpg"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <p class="josefin-light p pt-14 text-center text-lg-left">
            {{ $t("programs.documentCertified") }}
          </p>
        </v-row>
      </v-container>
    </v-row>
    <v-row class="odd">
      <v-container class="pa-lg-16 pa-10">
        <p
          class="josefin-light p text-center text-lg-left"
          v-html="$t('programs.moreInformation')"
        ></p>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import banner from "@/components/home/banner.vue";

export default {
  components: {
    banner
  },
  data() {
    return {};
  },

  methods: {}
};
</script>
<style lang="scss" scoped>
.listul {
  list-style: none !important;
}

.listul li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
</style>
